<div *ngIf="!isInitialized" class="mosaic-loader" [class.mosaic-loader-visible]="showLoader">
    <img src="./assets/img/mosaic_logo.svg">
    <mat-progress-bar mode="indeterminate" *ngIf="!isInitializationError"></mat-progress-bar>
    <div class="mosaic-loader-error" *ngIf="isInitializationError">
        <mat-icon matSuffix>error</mat-icon>
        <p>
            An issue has occurred while initializing the Administration Portal. Please refresh your page and try again.
        </p>
    </div>
</div>

<ng-container *ngIf="isInitialized">

    <!-- Admin header -->
    <div *ngIf="isManagementClient && isLoggedIn && !hideNavigation()">
        <app-nav-admin [isMosaic]="isMosaic"></app-nav-admin>
    </div>

    <!-- New version banner -->
    <div class="new-feature-banner" *ngIf="isLoggedIn && isNewVersion">
        <div class="wrapper" fxLayout="row" fxLayoutAlign="space-between center">
            <span>
                <b class="badge">Portal Update</b>
                <i class="fas fa-sparkles"></i>
                A new version of the administration portal is available! Please refresh the page to receive the update.
            </span>

            <div class="btn-group">
                <a class="btn btn-white" color="primary" href=".">Refresh</a>
            </div>
        </div>
    </div>

    <!-- New features banner -->
    <div class="new-feature-banner" *ngIf="!hideBanner" fxHide>
        <div class="wrapper" fxLayout="row" fxLayoutAlign="space-between center">
            <span>
                <b class="badge">New Feature</b>
                <i class="fas fa-mountains"></i>
                <b> Campaigns</b> — Create discounts and promotions to drive adoption and engagement.
            </span>

            <div class="btn-group">
                <a class="btn btn-flat" href="https://mosaicsupport.zendesk.com/hc/en-us/sections/360007996474-Campaigns-Module" target="_blank">Learn More</a>
                <a class="btn btn-white" color="primary" href="/campaigns">Create a Campaign</a>

                <!-- When the user closes/hides the banner, make that change permanent -->
                <span class="close" (click)="hideBanner = true"><i class="far fa-times"></i></span>
            </div>
        </div>
    </div>

    <mat-sidenav-container [class.is-admin]="isManagementClient" [class.login-screen]="!isLoggedIn">

        <!-- Client sidebar -->
        <mat-sidenav *ngIf="isLoggedIn && !hideNavigation()" #sidenav mode="{{navMode}}" opened="{{state}}" (click)="isMobile && sidenav.toggle()" style="border-right: 1px solid #e7e7e7;">
            <app-nav></app-nav>
        </mat-sidenav>

        <!-- Page -->
        <div class="main-content" [class.login-screen]="!isLoggedIn">
            <button mat-fab color="primary" (click)="sidenav.toggle()" fxHide.gt-md class="menu-fab">
                <mat-icon aria-label="Open side nav">menu</mat-icon>
            </button>

            <router-outlet></router-outlet>

        </div>

    </mat-sidenav-container>

</ng-container>
import { Component, Input, OnInit } from '@angular/core';
import { ClientsService, Client } from "@admin/clients/clients.service";
import { Subscription } from "rxjs";
import { Router } from '@angular/router';
import { Subscriptions } from '@shared/lib/Subscriptions';
import { MatDialog } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';

import { AddClientComponent } from "./add-client/add-client.component";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-nav-admin',
    templateUrl: './nav-admin.component.html',
    styleUrls: ['./nav-admin.component.scss']
})
export class NavAdminComponent implements OnInit {

    @Input('isMosaic') isMosaic: boolean = false;

    public clients: Client[];
    public clientLock: boolean = false;
    public activeClient: Client;
    private _subscriptions: Subscription[] = [];

    constructor(
        public dialog: MatDialog,
        private _clientService: ClientsService,
        private _clipboard: Clipboard,
        private _snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this._subscribeToEvents();
    }

    public openDialog(): void {
        let dialogRef = this.dialog.open(AddClientComponent, {
            width: '900px',
            disableClose: true,
            data: {
                clients: this.clients
            }
        });
    }

    _subscribeToEvents() {

        //Listen to when the client list is updated
        this._subscriptions.push(this._clientService.clients.onUpdate()
            .subscribe((clients: Client[]) => {

                //Overwrite current clients
                this.clients = clients

            }))

        //Listen to when the client list is updated
        this._subscriptions.push(this._clientService.clients.onActiveElementChange()
            .subscribe((client: Client) => {

                //Overwrite current active client
                this.activeClient = client;

            }))

        //Listen to when the client list is updated
        this._subscriptions.push(this._clientService.clientLock
            .subscribe((clientLock: boolean) => {

                //Set if the client is locked from swapping
                this.clientLock = clientLock;

            }))

    }

    ngOnDestroy() {
        Subscriptions.unsubscribe(this._subscriptions)
    }


    selectClient(clientId: string) {

        //Find the selected client
        let index = this.clients.findIndex((client: Client) => {
            return client.clientId == clientId;
        })

        //Set the active client
        if (index >= 0) this._clientService.clients.setActiveElementById(index)

    }

    selectCWC() {
        //Find the selected client
        let index = this.clients.findIndex((client: Client) => {
            return client.isCwc == true;
        })

        //Set the active client
        if (index >= 0) this._clientService.clients.setActiveElementById(index)
    }

    copyClientId(): void {
        let clientId = this.activeClient ? this.activeClient.clientId : 'Unknown Client Id';
        this._clipboard.copy(`${clientId}`);
        this._snackBar.open("Client ID has been copied to the clipboard.", "Dismiss", { duration: 2000 })
    }
}
